import createStore from "./createStore.js"
import * as elements from './elements.js'
import d3AnimationView from "./view/View.d3Animation.js"
import initialFamilyData from "./initialData.js"

import './family-tree.css';

import React, { useEffect } from 'react';

interface FamilyTreeProps {
    cardDisplayType: string;
}

const FamilyTree: React.FC<FamilyTreeProps> = ({ cardDisplayType }) => {
    const store = createStore({
        data: initialFamilyData,
        node_separation: 250, // distance between siblings
        level_separation: 250, // distance between parent and children
    })

    const squareDimensionsWithNameAtBottom = {
        w: 100,
        h: 140,
        text_x: 10,
        text_y: 105,
        img_w: 90,
        img_h: 90,
        img_x: 5,
        img_y: 5,
    };

    const rectangleDimensions = {
        w: 220,
        h: 100,
        text_x: 100,
        text_y: 15,
        img_w: 90,
        img_h: 90,
        img_x: 5,
        img_y: 5,
    }

    useEffect(() => {
        const view = d3AnimationView({
            store: store,
            cont: document.querySelector("#FamilyChart"),
            Card: null
        });
        const cardView = elements.Card({
            store,
            svg: view.svg,
            card_dim: cardDisplayType == "rectangle" ? rectangleDimensions : squareDimensionsWithNameAtBottom,
            card_display: [
                (d: { data: { [x: string]: any; }; }) => `${d.data["first_name"] || ""}`,
                (d: { data: { [x: string]: any; }; }) => `${d.data["last_name"] || ""}`,
                (d: { data: { [x: string]: any; }; }) => `${d.data["birthday"] || ""}`,
            ],
            mini_tree: true,
            link_break: false,
        });
        view.setCard(cardView)
        store.setOnUpdate((props: any) => view.update(props || {}));
        store.update.tree({ tree_position: "main_to_middle" });
    }, []);

    return <div>
        <div id="FamilyChart" className="f3"></div>
    </div>;
};

const EditFamilyTree: React.FC = () => {
    const store = createStore({
        data: initialFamilyData,
        node_separation: 250,
        level_separation: 150,
    })

    const squareDimensionsWithNameAtBottom = {
        w: 100,
        h: 140,
        text_x: 10,
        text_y: 105,
        img_w: 90,
        img_h: 90,
        img_x: 5,
        img_y: 5,
    };

    const rectangleDimensions = {
        w: 220,
        h: 100,
        text_x: 100,
        text_y: 15,
        img_w: 90,
        img_h: 90,
        img_x: 5,
        img_y: 5,
    }

    useEffect(() => {
        const view = d3AnimationView({
            store: store,
            cont: document.querySelector("#FamilyChart"),
            Card: null
        });
        const cardView = elements.Card({
            store,
            svg: view.svg,
            card_dim: rectangleDimensions,
            card_display: [
                (d: { data: { [x: string]: any; }; }) => `${d.data["first_name"] || ""}`,
                (d: { data: { [x: string]: any; }; }) => `${d.data["last_name"] || ""}`,
                (d: { data: { [x: string]: any; }; }) => `${d.data["birthday"] || ""}`,
            ],
            mini_tree: true,
            link_break: false,
        });
        view.setCard(cardView)
        store.setOnUpdate((props: any) => view.update(props || {}));
        store.update.tree({ tree_position: "main_to_middle" });
    }, []);

    return <div>
        <div id="FamilyChart" className="f3"></div>
    </div>;
}

export default FamilyTree;

export { EditFamilyTree };
