import React, { useRef, useEffect } from "react";
import Tree from "react-d3-tree";

import { RectangleCardView } from "./Views";

import orgChart from "./data/data";
import "./styles.css";

const containerStyles = {
    width: "100vw",
    height: "100vh"
};

const MyTree: React.FC = () => {
    const useCenterTree = useRef<HTMLDivElement>(null);
    const [translate, setTranslate] = React.useState({ x: 0, y: 0 });
    const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

    useEffect(() => {
        if (useCenterTree.current) {
            const { width, height } = useCenterTree.current.getBoundingClientRect();
            setDimensions({ width, height });
            setTranslate({ x: width / 2, y: height / 2 });
        }
    }, []);

    return (
        <div style={containerStyles} ref={useCenterTree}>
            <Tree
                separation={{ siblings: 3, nonSiblings: 3 }}
                data={orgChart}
                orientation="vertical"
                dimensions={dimensions}
                translate={translate}
                initialDepth={0}
                centeringTransitionDuration={500}
                renderCustomNodeElement={(rd3tProps) => RectangleCardView({ ...rd3tProps })
                }
            />
        </div>
    )
}

export default MyTree;