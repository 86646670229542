import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import ReactGA from 'react-ga';


const Navigation: React.FC = () => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);
    return (
        <div>
            {/* <nav>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                </ul>
            </nav> */}
        </div>
    );
};

export default Navigation;
