export default [
  {
    id: "0",
    rels: {
      spouses: ["507a4e1a-f715-460f-9270-5451228aa7bd"],
      children: [
        "9f1ecb18-7f98-4d79-a57c-c0620d6ab9f4",
        "84c7658a-d52b-46c4-a1c5-f952af44ba7d",
        "13e1003a-224e-43f4-88a0-83319d9da618",
      ],
    },
    data: {
      first_name: "Dhanpati",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/",
      gender: "M",
    },
  },
  {
    id: "507a4e1a-f715-460f-9270-5451228aa7bd",
    data: {
      gender: "F",
      first_name: "Madhusudhan",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/507a4e1a-f715-460f-9270-5451228aa7bd.png",
    },
    rels: {
      spouses: ["0"],
      children: [
        "9f1ecb18-7f98-4d79-a57c-c0620d6ab9f4",
        "84c7658a-d52b-46c4-a1c5-f952af44ba7d",
        "13e1003a-224e-43f4-88a0-83319d9da618",
      ],
    },
  },
  {
    id: "9f1ecb18-7f98-4d79-a57c-c0620d6ab9f4",
    data: {
      gender: "M",
      first_name: "Pashupati",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/9f1ecb18-7f98-4d79-a57c-c0620d6ab9f4.png",
    },
    rels: {
      mother: "507a4e1a-f715-460f-9270-5451228aa7bd",
      father: "0",
    },
  },
  {
    id: "84c7658a-d52b-46c4-a1c5-f952af44ba7d",
    data: {
      gender: "M",
      first_name: "Ghashiram",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/84c7658a-d52b-46c4-a1c5-f952af44ba7d.png",
    },
    rels: {
      mother: "507a4e1a-f715-460f-9270-5451228aa7bd",
      father: "0",
      spouses: ["58ad5b1a-b7fb-4e7b-89a6-5343f08e1f19"],
      children: [
        "bbd2ba11-91d8-49c7-a859-aa768bf15fe8",
        "86e88760-bbab-4b6c-abc1-b1cb5331dcc3",
      ],
    },
  },
  {
    id: "13e1003a-224e-43f4-88a0-83319d9da618",
    data: {
      gender: "M",
      first_name: "Chandradatta",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/13e1003a-224e-43f4-88a0-83319d9da618.png",
    },
    rels: {
      mother: "507a4e1a-f715-460f-9270-5451228aa7bd",
      father: "0",
      spouses: ["135c6423-5095-4b35-9cfd-84dc20ab3f85"],
    },
  },
  {
    id: "58ad5b1a-b7fb-4e7b-89a6-5343f08e1f19",
    data: {
      gender: "F",
      first_name: "Rukmani",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/58ad5b1a-b7fb-4e7b-89a6-5343f08e1f19.png",
    },
    rels: {
      spouses: ["84c7658a-d52b-46c4-a1c5-f952af44ba7d"],
      children: [
        "bbd2ba11-91d8-49c7-a859-aa768bf15fe8",
        "86e88760-bbab-4b6c-abc1-b1cb5331dcc3",
      ],
    },
  },
  {
    id: "bbd2ba11-91d8-49c7-a859-aa768bf15fe8",
    data: {
      gender: "M",
      first_name: "Maheswor",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/bbd2ba11-91d8-49c7-a859-aa768bf15fe8.png",
    },
    rels: {
      mother: "58ad5b1a-b7fb-4e7b-89a6-5343f08e1f19",
      father: "84c7658a-d52b-46c4-a1c5-f952af44ba7d",
      spouses: ["47af5616-7b87-44f7-abae-dcf0301fc8c4"],
      children: [
        "47451da7-f331-466f-8b16-3a7db658b72a",
        "49fd2738-d569-458a-a6ee-9eee355503a1",
        "e53cb2b7-3b0f-4fc7-92d1-f0001d19e8b2",
        "b2406e44-76a3-44cc-a635-1056c1723c37",
      ],
    },
  },
  {
    id: "47af5616-7b87-44f7-abae-dcf0301fc8c4",
    data: {
      gender: "F",
      first_name: "Rewati",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/47af5616-7b87-44f7-abae-dcf0301fc8c4.png",
    },
    rels: {
      spouses: ["bbd2ba11-91d8-49c7-a859-aa768bf15fe8"],
      children: [
        "47451da7-f331-466f-8b16-3a7db658b72a",
        "49fd2738-d569-458a-a6ee-9eee355503a1",
        "e53cb2b7-3b0f-4fc7-92d1-f0001d19e8b2",
        "b2406e44-76a3-44cc-a635-1056c1723c37",
      ],
    },
  },
  {
    id: "47451da7-f331-466f-8b16-3a7db658b72a",
    data: {
      gender: "F",
      first_name: "Jamuna Kumari",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/47451da7-f331-466f-8b16-3a7db658b72a.png",
    },
    rels: {
      mother: "47af5616-7b87-44f7-abae-dcf0301fc8c4",
      father: "bbd2ba11-91d8-49c7-a859-aa768bf15fe8",
      spouses: ["cfc62c04-8a7a-49ad-bdac-62f5e0e1424e"],
    },
  },
  {
    id: "cfc62c04-8a7a-49ad-bdac-62f5e0e1424e",
    data: {
      gender: "M",
      first_name: "Sulochan",
      last_name: "Sapkota",
      birthday: "",
      avatar: "images/cfc62c04-8a7a-49ad-bdac-62f5e0e1424e.png",
    },
    rels: {
      spouses: ["47451da7-f331-466f-8b16-3a7db658b72a"],
    },
  },
  {
    id: "49fd2738-d569-458a-a6ee-9eee355503a1",
    data: {
      gender: "F",
      first_name: "Ratna Kumari",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/49fd2738-d569-458a-a6ee-9eee355503a1.png",
    },
    rels: {
      mother: "47af5616-7b87-44f7-abae-dcf0301fc8c4",
      father: "bbd2ba11-91d8-49c7-a859-aa768bf15fe8",
      spouses: ["17de59fd-ca60-4a33-bff8-80945bb56929"],
    },
  },
  {
    id: "17de59fd-ca60-4a33-bff8-80945bb56929",
    data: {
      gender: "M",
      first_name: "Bhagirath",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/17de59fd-ca60-4a33-bff8-80945bb56929.png",
    },
    rels: {
      spouses: ["49fd2738-d569-458a-a6ee-9eee355503a1"],
    },
  },
  {
    id: "e53cb2b7-3b0f-4fc7-92d1-f0001d19e8b2",
    data: {
      gender: "M",
      first_name: "Devi Prashad",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/e53cb2b7-3b0f-4fc7-92d1-f0001d19e8b2.png",
    },
    rels: {
      mother: "47af5616-7b87-44f7-abae-dcf0301fc8c4",
      father: "bbd2ba11-91d8-49c7-a859-aa768bf15fe8",
      spouses: ["baa356c8-8d61-4dd3-9426-601f7ca1eecd"],
      children: [
        "35656578-64f8-4341-b328-e37abb700ec8",
        "91a15a31-e585-47f8-a634-b6a20dd07f4f",
        "10030cb7-3f7e-47ae-9bcf-f4c828f19812",
      ],
    },
  },
  {
    id: "baa356c8-8d61-4dd3-9426-601f7ca1eecd",
    data: {
      gender: "F",
      first_name: "Sita Kumari",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/baa356c8-8d61-4dd3-9426-601f7ca1eecd.png",
    },
    rels: {
      spouses: ["e53cb2b7-3b0f-4fc7-92d1-f0001d19e8b2"],
      children: [
        "35656578-64f8-4341-b328-e37abb700ec8",
        "91a15a31-e585-47f8-a634-b6a20dd07f4f",
        "10030cb7-3f7e-47ae-9bcf-f4c828f19812",
      ],
    },
  },
  {
    id: "35656578-64f8-4341-b328-e37abb700ec8",
    data: {
      gender: "F",
      first_name: "Purnakala",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/35656578-64f8-4341-b328-e37abb700ec8.png",
    },
    rels: {
      mother: "baa356c8-8d61-4dd3-9426-601f7ca1eecd",
      father: "e53cb2b7-3b0f-4fc7-92d1-f0001d19e8b2",
      spouses: ["74a3a4a3-5298-4ddf-a91d-e3af426ae592"],
      children: [
        "e3da8ce2-5b3c-429e-b132-dcfcb37beff9",
        "74398a44-5a17-47dc-bdf1-34383a06d356",
        "42ad921b-4f3b-4c65-aa32-137cf65225ae",
      ],
    },
  },
  {
    id: "74a3a4a3-5298-4ddf-a91d-e3af426ae592",
    data: {
      gender: "M",
      first_name: "Shankhar",
      last_name: "Parajuli",
      birthday: "",
      avatar: "images/74a3a4a3-5298-4ddf-a91d-e3af426ae592.png",
    },
    rels: {
      spouses: ["35656578-64f8-4341-b328-e37abb700ec8"],
      children: [
        "e3da8ce2-5b3c-429e-b132-dcfcb37beff9",
        "74398a44-5a17-47dc-bdf1-34383a06d356",
        "42ad921b-4f3b-4c65-aa32-137cf65225ae",
      ],
    },
  },
  {
    id: "e3da8ce2-5b3c-429e-b132-dcfcb37beff9",
    data: {
      gender: "M",
      first_name: "Surendra",
      last_name: "Parajuli",
      birthday: "",
      avatar: "images/e3da8ce2-5b3c-429e-b132-dcfcb37beff9.png",
    },
    rels: {
      mother: "35656578-64f8-4341-b328-e37abb700ec8",
      father: "74a3a4a3-5298-4ddf-a91d-e3af426ae592",
    },
  },
  {
    id: "74398a44-5a17-47dc-bdf1-34383a06d356",
    data: {
      gender: "M",
      first_name: "Santosh",
      last_name: "Parajuli",
      birthday: "",
      avatar: "images/74398a44-5a17-47dc-bdf1-34383a06d356.png",
    },
    rels: {
      mother: "35656578-64f8-4341-b328-e37abb700ec8",
      father: "74a3a4a3-5298-4ddf-a91d-e3af426ae592",
    },
  },
  {
    id: "42ad921b-4f3b-4c65-aa32-137cf65225ae",
    data: {
      gender: "F",
      first_name: "Manju",
      last_name: "Parajuli",
      birthday: "",
      avatar: "images/42ad921b-4f3b-4c65-aa32-137cf65225ae.png",
    },
    rels: {
      mother: "35656578-64f8-4341-b328-e37abb700ec8",
      father: "74a3a4a3-5298-4ddf-a91d-e3af426ae592",
    },
  },
  {
    id: "91a15a31-e585-47f8-a634-b6a20dd07f4f",
    data: {
      gender: "F",
      first_name: "Goma",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/91a15a31-e585-47f8-a634-b6a20dd07f4f.png",
    },
    rels: {
      mother: "baa356c8-8d61-4dd3-9426-601f7ca1eecd",
      father: "e53cb2b7-3b0f-4fc7-92d1-f0001d19e8b2",
      spouses: ["ee58b3c6-0bf1-407d-9162-62fd5a2ea813"],
    },
  },
  {
    id: "ee58b3c6-0bf1-407d-9162-62fd5a2ea813",
    data: {
      gender: "M",
      first_name: "Shreemad",
      last_name: "Gautam",
      birthday: "",
      avatar: "images/ee58b3c6-0bf1-407d-9162-62fd5a2ea813.png",
    },
    rels: {
      spouses: ["91a15a31-e585-47f8-a634-b6a20dd07f4f"],
    },
  },
  {
    id: "10030cb7-3f7e-47ae-9bcf-f4c828f19812",
    data: {
      gender: "F",
      first_name: "Ganga",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/10030cb7-3f7e-47ae-9bcf-f4c828f19812.png",
    },
    rels: {
      mother: "baa356c8-8d61-4dd3-9426-601f7ca1eecd",
      father: "e53cb2b7-3b0f-4fc7-92d1-f0001d19e8b2",
      spouses: ["781d01ce-fb7b-4d83-815e-4705d264b15b"],
      children: ["4d22d474-74bc-4849-9456-4c17fbe3e240"],
    },
  },
  {
    id: "781d01ce-fb7b-4d83-815e-4705d264b15b",
    data: {
      gender: "M",
      first_name: "Uddhav",
      last_name: "Gautam",
      birthday: "",
      avatar: "images/781d01ce-fb7b-4d83-815e-4705d264b15b.png",
    },
    rels: {
      spouses: ["10030cb7-3f7e-47ae-9bcf-f4c828f19812"],
      children: ["4d22d474-74bc-4849-9456-4c17fbe3e240"],
    },
  },
  {
    id: "4d22d474-74bc-4849-9456-4c17fbe3e240",
    data: {
      gender: "F",
      first_name: "Susmita",
      last_name: "Gautam",
      birthday: "",
      avatar: "images/4d22d474-74bc-4849-9456-4c17fbe3e240.png",
    },
    rels: {
      father: "781d01ce-fb7b-4d83-815e-4705d264b15b",
      mother: "10030cb7-3f7e-47ae-9bcf-f4c828f19812",
    },
  },
  {
    id: "86e88760-bbab-4b6c-abc1-b1cb5331dcc3",
    data: {
      gender: "M",
      first_name: "Laxmi Prashad",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/86e88760-bbab-4b6c-abc1-b1cb5331dcc3.png",
    },
    rels: {
      mother: "58ad5b1a-b7fb-4e7b-89a6-5343f08e1f19",
      father: "84c7658a-d52b-46c4-a1c5-f952af44ba7d",
      spouses: ["c8f59027-a3ee-4b1b-83f8-1c0bc5970e32"],
      children: [
        "7d9cb12a-80d6-4754-9223-5c1241972330",
        "9ccd4ba0-fbde-44bf-b3a2-e1478df08624",
        "b432fc62-ab64-4908-9f02-3233f5e49c35",
        "fa0e085f-9f57-42a3-9a22-1564e452a1a9",
      ],
    },
  },
  {
    id: "c8f59027-a3ee-4b1b-83f8-1c0bc5970e32",
    data: {
      gender: "F",
      first_name: "Khagweshori",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/c8f59027-a3ee-4b1b-83f8-1c0bc5970e32.png",
    },
    rels: {
      spouses: ["86e88760-bbab-4b6c-abc1-b1cb5331dcc3"],
      children: [
        "7d9cb12a-80d6-4754-9223-5c1241972330",
        "9ccd4ba0-fbde-44bf-b3a2-e1478df08624",
        "b432fc62-ab64-4908-9f02-3233f5e49c35",
        "fa0e085f-9f57-42a3-9a22-1564e452a1a9",
      ],
    },
  },
  {
    id: "7d9cb12a-80d6-4754-9223-5c1241972330",
    data: {
      gender: "M",
      first_name: "Hari Prashad",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/7d9cb12a-80d6-4754-9223-5c1241972330.png",
    },
    rels: {
      mother: "c8f59027-a3ee-4b1b-83f8-1c0bc5970e32",
      father: "86e88760-bbab-4b6c-abc1-b1cb5331dcc3",
      spouses: [
        "d13571cc-3033-4812-9716-437ecb203668",
        "d92fe267-6683-44df-84c1-7b161b102749",
      ],
      children: [
        "65d5eb23-563c-46c6-b9e4-ecd9bbbe52ca",
        "2a50b505-6b3a-4526-9e55-ebdcd3fdb62a",
        "c10892ba-1a5d-43e8-8a47-335ebd2e24d4",
        "e7c97925-25ff-49ca-974d-3a7500222a27",
        "6cfc499d-0518-43dc-b1c7-d9d01c3c654f",
        "94054f6f-2743-42da-8da7-3eb6a23dd2ef",
      ],
    },
  },
  {
    id: "d13571cc-3033-4812-9716-437ecb203668",
    data: {
      gender: "F",
      first_name: "Hima Devi",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/d13571cc-3033-4812-9716-437ecb203668.png",
    },
    rels: {
      spouses: ["7d9cb12a-80d6-4754-9223-5c1241972330"],
      children: [
        "65d5eb23-563c-46c6-b9e4-ecd9bbbe52ca",
        "2a50b505-6b3a-4526-9e55-ebdcd3fdb62a",
      ],
    },
  },
  {
    id: "d92fe267-6683-44df-84c1-7b161b102749",
    data: {
      gender: "F",
      first_name: "Purnakala",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/d92fe267-6683-44df-84c1-7b161b102749.png",
    },
    rels: {
      spouses: ["7d9cb12a-80d6-4754-9223-5c1241972330"],
      children: [
        "c10892ba-1a5d-43e8-8a47-335ebd2e24d4",
        "e7c97925-25ff-49ca-974d-3a7500222a27",
        "6cfc499d-0518-43dc-b1c7-d9d01c3c654f",
        "94054f6f-2743-42da-8da7-3eb6a23dd2ef",
      ],
    },
  },
  {
    id: "65d5eb23-563c-46c6-b9e4-ecd9bbbe52ca",
    data: {
      gender: "F",
      first_name: "Sabitri",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/65d5eb23-563c-46c6-b9e4-ecd9bbbe52ca.png",
    },
    rels: {
      father: "7d9cb12a-80d6-4754-9223-5c1241972330",
      mother: "d13571cc-3033-4812-9716-437ecb203668",
      spouses: ["57a82720-7c69-45da-9065-854980f9fcf5"],
      children: [
        "f3f1e4f6-c8e5-4732-bbfa-99640c837c90",
        "6ab86df2-6b5f-4d7e-8825-b8b07909369f",
        "11d15c00-912e-41c0-8eb6-8917fb155d27",
      ],
    },
  },
  {
    id: "57a82720-7c69-45da-9065-854980f9fcf5",
    data: {
      gender: "M",
      first_name: "Prem Bahadur",
      last_name: "Khadka",
      birthday: "",
      avatar: "images/57a82720-7c69-45da-9065-854980f9fcf5.png",
    },
    rels: {
      spouses: ["65d5eb23-563c-46c6-b9e4-ecd9bbbe52ca"],
      children: [
        "f3f1e4f6-c8e5-4732-bbfa-99640c837c90",
        "6ab86df2-6b5f-4d7e-8825-b8b07909369f",
        "11d15c00-912e-41c0-8eb6-8917fb155d27",
      ],
    },
  },
  {
    id: "f3f1e4f6-c8e5-4732-bbfa-99640c837c90",
    data: {
      gender: "M",
      first_name: "Manoj",
      last_name: "Khadka",
      birthday: "",
      avatar: "images/f3f1e4f6-c8e5-4732-bbfa-99640c837c90.png",
    },
    rels: {
      mother: "65d5eb23-563c-46c6-b9e4-ecd9bbbe52ca",
      father: "57a82720-7c69-45da-9065-854980f9fcf5",
      spouses: ["095c9c3c-6a64-49ec-a9b2-5a05c3581f0e"],
      children: [
        "44df6e25-44c9-4d3d-ac0a-21698c6b2b15",
        "431cb893-12e2-4a86-be8f-8582b7b1d846",
      ],
    },
  },
  {
    id: "095c9c3c-6a64-49ec-a9b2-5a05c3581f0e",
    data: {
      gender: "F",
      first_name: "Kalpana",
      last_name: "Khadka",
      birthday: "",
      avatar: "images/095c9c3c-6a64-49ec-a9b2-5a05c3581f0e.png",
    },
    rels: {
      spouses: ["f3f1e4f6-c8e5-4732-bbfa-99640c837c90"],
      children: [
        "44df6e25-44c9-4d3d-ac0a-21698c6b2b15",
        "431cb893-12e2-4a86-be8f-8582b7b1d846",
      ],
    },
  },
  {
    id: "44df6e25-44c9-4d3d-ac0a-21698c6b2b15",
    data: {
      gender: "F",
      first_name: "Jasmine",
      last_name: "Khadka",
      birthday: "",
      avatar: "images/44df6e25-44c9-4d3d-ac0a-21698c6b2b15.png",
    },
    rels: {
      mother: "095c9c3c-6a64-49ec-a9b2-5a05c3581f0e",
      father: "f3f1e4f6-c8e5-4732-bbfa-99640c837c90",
    },
  },
  {
    id: "431cb893-12e2-4a86-be8f-8582b7b1d846",
    data: {
      gender: "M",
      first_name: "Sushant",
      last_name: "Khadka",
      birthday: "",
      avatar: "images/431cb893-12e2-4a86-be8f-8582b7b1d846.png",
    },
    rels: {
      mother: "095c9c3c-6a64-49ec-a9b2-5a05c3581f0e",
      father: "f3f1e4f6-c8e5-4732-bbfa-99640c837c90",
    },
  },
  {
    id: "6ab86df2-6b5f-4d7e-8825-b8b07909369f",
    data: {
      gender: "M",
      first_name: "Sanjeev Kumar",
      last_name: "Khadka",
      birthday: "",
      avatar: "images/6ab86df2-6b5f-4d7e-8825-b8b07909369f.png",
    },
    rels: {
      mother: "65d5eb23-563c-46c6-b9e4-ecd9bbbe52ca",
      father: "57a82720-7c69-45da-9065-854980f9fcf5",
      spouses: ["69e0bab5-ea8b-4329-830c-a7523abf480a"],
      children: [
        "6f766f0b-eb3b-4790-9309-0092790f4f9c",
        "5b8d9efd-6874-4f49-830a-795b164eaabb",
      ],
    },
  },
  {
    id: "69e0bab5-ea8b-4329-830c-a7523abf480a",
    data: {
      gender: "F",
      first_name: "Sunita Kumari",
      last_name: "Khadka",
      birthday: "",
      avatar: "images/69e0bab5-ea8b-4329-830c-a7523abf480a.png",
    },
    rels: {
      spouses: ["6ab86df2-6b5f-4d7e-8825-b8b07909369f"],
      children: [
        "6f766f0b-eb3b-4790-9309-0092790f4f9c",
        "5b8d9efd-6874-4f49-830a-795b164eaabb",
      ],
    },
  },
  {
    id: "6f766f0b-eb3b-4790-9309-0092790f4f9c",
    data: {
      gender: "M",
      first_name: "Saugat",
      last_name: "Khadka",
      birthday: "",
      avatar: "images/6f766f0b-eb3b-4790-9309-0092790f4f9c.png",
    },
    rels: {
      mother: "69e0bab5-ea8b-4329-830c-a7523abf480a",
      father: "6ab86df2-6b5f-4d7e-8825-b8b07909369f",
    },
  },
  {
    id: "5b8d9efd-6874-4f49-830a-795b164eaabb",
    data: {
      gender: "M",
      first_name: "Saksham",
      last_name: "Khadka",
      birthday: "",
      avatar: "images/5b8d9efd-6874-4f49-830a-795b164eaabb.png",
    },
    rels: {
      mother: "69e0bab5-ea8b-4329-830c-a7523abf480a",
      father: "6ab86df2-6b5f-4d7e-8825-b8b07909369f",
    },
  },
  {
    id: "11d15c00-912e-41c0-8eb6-8917fb155d27",
    data: {
      gender: "F",
      first_name: "Shova Kumari",
      last_name: "Khadka",
      birthday: "",
      avatar: "images/11d15c00-912e-41c0-8eb6-8917fb155d27.png",
    },
    rels: {
      mother: "65d5eb23-563c-46c6-b9e4-ecd9bbbe52ca",
      father: "57a82720-7c69-45da-9065-854980f9fcf5",
      spouses: ["d3a3b6bf-1556-4e9b-8353-53b9638d824d"],
      children: ["c3c4fe1c-d929-4827-b8ce-ba2dd87a9fc2"],
    },
  },
  {
    id: "d3a3b6bf-1556-4e9b-8353-53b9638d824d",
    data: {
      gender: "M",
      first_name: "Suraj",
      last_name: "Thapa",
      birthday: "",
      avatar: "images/d3a3b6bf-1556-4e9b-8353-53b9638d824d.png",
    },
    rels: {
      spouses: ["11d15c00-912e-41c0-8eb6-8917fb155d27"],
      children: ["c3c4fe1c-d929-4827-b8ce-ba2dd87a9fc2"],
    },
  },
  {
    id: "c3c4fe1c-d929-4827-b8ce-ba2dd87a9fc2",
    data: {
      gender: "M",
      first_name: "Shuvam",
      last_name: "Khadka",
      birthday: "",
      avatar: "images/c3c4fe1c-d929-4827-b8ce-ba2dd87a9fc2.png",
    },
    rels: {
      father: "d3a3b6bf-1556-4e9b-8353-53b9638d824d",
      mother: "11d15c00-912e-41c0-8eb6-8917fb155d27",
    },
  },
  {
    id: "2a50b505-6b3a-4526-9e55-ebdcd3fdb62a",
    data: {
      gender: "M",
      first_name: "Ramchandra",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/2a50b505-6b3a-4526-9e55-ebdcd3fdb62a.png",
    },
    rels: {
      mother: "d13571cc-3033-4812-9716-437ecb203668",
      father: "7d9cb12a-80d6-4754-9223-5c1241972330",
      spouses: ["0085e8e5-130f-4974-9e7c-d3d9dafcab74"],
      children: [
        "5e1aba21-390a-4d39-b358-1f3fc61f0235",
        "876f84da-c2d0-46f3-b7ec-53036e817b23",
        "15b51f96-0256-4144-8dcc-0d57dbad3f3d",
        "3c0de658-7362-4cb2-841f-f86d3473c99e",
      ],
    },
  },
  {
    id: "0085e8e5-130f-4974-9e7c-d3d9dafcab74",
    data: {
      gender: "F",
      first_name: "Tara Devi",
      last_name: "Chhetri",
      birthday: "",
      avatar: "images/0085e8e5-130f-4974-9e7c-d3d9dafcab74.png",
    },
    rels: {
      spouses: ["2a50b505-6b3a-4526-9e55-ebdcd3fdb62a"],
      children: [
        "5e1aba21-390a-4d39-b358-1f3fc61f0235",
        "876f84da-c2d0-46f3-b7ec-53036e817b23",
        "15b51f96-0256-4144-8dcc-0d57dbad3f3d",
        "3c0de658-7362-4cb2-841f-f86d3473c99e",
      ],
    },
  },
  {
    id: "5e1aba21-390a-4d39-b358-1f3fc61f0235",
    data: {
      gender: "F",
      first_name: "Sunita",
      last_name: "Chhetri",
      birthday: "",
      avatar: "images/5e1aba21-390a-4d39-b358-1f3fc61f0235.png",
    },
    rels: {
      mother: "0085e8e5-130f-4974-9e7c-d3d9dafcab74",
      father: "2a50b505-6b3a-4526-9e55-ebdcd3fdb62a",
      spouses: ["06992abb-36b7-4bef-9ac2-f88d3aa27b96"],
      children: ["76ae8e77-fafd-4c5b-a58d-89e131642c8b"],
    },
  },
  {
    id: "06992abb-36b7-4bef-9ac2-f88d3aa27b96",
    data: {
      gender: "M",
      first_name: "Arvind Kumar",
      last_name: "Singh",
      birthday: "",
      avatar: "images/06992abb-36b7-4bef-9ac2-f88d3aa27b96.png",
    },
    rels: {
      spouses: ["5e1aba21-390a-4d39-b358-1f3fc61f0235"],
      children: ["76ae8e77-fafd-4c5b-a58d-89e131642c8b"],
    },
  },
  {
    id: "76ae8e77-fafd-4c5b-a58d-89e131642c8b",
    data: {
      gender: "F",
      first_name: "Hossana",
      last_name: "Chhetri",
      birthday: "",
      avatar: "images/76ae8e77-fafd-4c5b-a58d-89e131642c8b.png",
    },
    rels: {
      mother: "5e1aba21-390a-4d39-b358-1f3fc61f0235",
      father: "06992abb-36b7-4bef-9ac2-f88d3aa27b96",
    },
  },
  {
    id: "876f84da-c2d0-46f3-b7ec-53036e817b23",
    data: {
      gender: "F",
      first_name: "Suraksha",
      last_name: "Chhetri",
      birthday: "",
      avatar: "images/876f84da-c2d0-46f3-b7ec-53036e817b23.png",
    },
    rels: {
      mother: "0085e8e5-130f-4974-9e7c-d3d9dafcab74",
      father: "2a50b505-6b3a-4526-9e55-ebdcd3fdb62a",
      spouses: ["471f9403-aa50-4d06-b8e2-969d9577cd0b"],
    },
  },
  {
    id: "471f9403-aa50-4d06-b8e2-969d9577cd0b",
    data: {
      gender: "M",
      first_name: "Prasanna",
      last_name: "Basnet",
      birthday: "",
      avatar: "images/471f9403-aa50-4d06-b8e2-969d9577cd0b.png",
    },
    rels: {
      spouses: ["876f84da-c2d0-46f3-b7ec-53036e817b23"],
    },
  },
  {
    id: "15b51f96-0256-4144-8dcc-0d57dbad3f3d",
    data: {
      gender: "M",
      first_name: "Gokul Khanal",
      last_name: "Chhetri",
      birthday: "",
      avatar: "images/15b51f96-0256-4144-8dcc-0d57dbad3f3d.png",
    },
    rels: {
      mother: "0085e8e5-130f-4974-9e7c-d3d9dafcab74",
      father: "2a50b505-6b3a-4526-9e55-ebdcd3fdb62a",
      spouses: ["a6780229-8206-4b3a-86d6-ba21df219263"],
      children: ["1e16cb2d-7301-41d9-b8f1-a0d35ca4737f"],
    },
  },
  {
    id: "a6780229-8206-4b3a-86d6-ba21df219263",
    data: {
      gender: "F",
      first_name: "Rama",
      last_name: "Thapa",
      birthday: "",
      avatar: "images/a6780229-8206-4b3a-86d6-ba21df219263.png",
    },
    rels: {
      spouses: ["15b51f96-0256-4144-8dcc-0d57dbad3f3d"],
      children: ["1e16cb2d-7301-41d9-b8f1-a0d35ca4737f"],
    },
  },
  {
    id: "1e16cb2d-7301-41d9-b8f1-a0d35ca4737f",
    data: {
      gender: "M",
      first_name: "Grishma Khanal",
      last_name: "Chhetri",
      birthday: "",
      avatar: "images/1e16cb2d-7301-41d9-b8f1-a0d35ca4737f.png",
    },
    rels: {
      mother: "a6780229-8206-4b3a-86d6-ba21df219263",
      father: "15b51f96-0256-4144-8dcc-0d57dbad3f3d",
    },
  },
  {
    id: "3c0de658-7362-4cb2-841f-f86d3473c99e",
    data: {
      gender: "M",
      first_name: "Amrit Khanal",
      last_name: "Chhetri",
      birthday: "",
      avatar: "images/3c0de658-7362-4cb2-841f-f86d3473c99e.jpg",
    },
    rels: {
      mother: "0085e8e5-130f-4974-9e7c-d3d9dafcab74",
      father: "2a50b505-6b3a-4526-9e55-ebdcd3fdb62a",
      spouses: ["59d7a5b4-9763-4fac-8b4e-5a7741774690"],
    },
  },
  {
    id: "59d7a5b4-9763-4fac-8b4e-5a7741774690",
    data: {
      gender: "F",
      first_name: "Sumitra",
      last_name: "KC",
      birthday: "",
      avatar: "images/59d7a5b4-9763-4fac-8b4e-5a7741774690.png",
    },
    rels: {
      spouses: ["3c0de658-7362-4cb2-841f-f86d3473c99e"],
    },
  },
  {
    id: "c10892ba-1a5d-43e8-8a47-335ebd2e24d4",
    data: {
      gender: "F",
      first_name: "Parvati",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/c10892ba-1a5d-43e8-8a47-335ebd2e24d4.png",
    },
    rels: {
      father: "7d9cb12a-80d6-4754-9223-5c1241972330",
      mother: "d92fe267-6683-44df-84c1-7b161b102749",
      spouses: ["1ee7581f-7c4d-449a-821a-03903a1ec79e"],
      children: [
        "bd431bca-c92d-4f47-b979-d259dbdfd4d2",
        "164e533e-54d6-4ca6-959a-502de785e8e0",
        "2919bd1d-0b87-4caf-b8e7-45465c96241f",
      ],
    },
  },
  {
    id: "1ee7581f-7c4d-449a-821a-03903a1ec79e",
    data: {
      gender: "M",
      first_name: "Gurudatta",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/1ee7581f-7c4d-449a-821a-03903a1ec79e.png",
    },
    rels: {
      spouses: ["c10892ba-1a5d-43e8-8a47-335ebd2e24d4"],
      children: [
        "bd431bca-c92d-4f47-b979-d259dbdfd4d2",
        "164e533e-54d6-4ca6-959a-502de785e8e0",
        "2919bd1d-0b87-4caf-b8e7-45465c96241f",
      ],
    },
  },
  {
    id: "bd431bca-c92d-4f47-b979-d259dbdfd4d2",
    data: {
      gender: "F",
      first_name: "Shova",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/bd431bca-c92d-4f47-b979-d259dbdfd4d2.png",
    },
    rels: {
      mother: "c10892ba-1a5d-43e8-8a47-335ebd2e24d4",
      father: "1ee7581f-7c4d-449a-821a-03903a1ec79e",
      spouses: ["031c799e-c89b-49a4-b75d-38248c8c435b"],
      children: [
        "9c5132c4-3821-4930-aeac-8fd51efd7754",
        "94cbc839-f8ef-41c0-8352-9789d7e3cd89",
      ],
    },
  },
  {
    id: "031c799e-c89b-49a4-b75d-38248c8c435b",
    data: {
      gender: "M",
      first_name: "Suraj",
      last_name: "Wagle",
      birthday: "",
      avatar: "images/031c799e-c89b-49a4-b75d-38248c8c435b.png",
    },
    rels: {
      spouses: ["bd431bca-c92d-4f47-b979-d259dbdfd4d2"],
      children: [
        "9c5132c4-3821-4930-aeac-8fd51efd7754",
        "94cbc839-f8ef-41c0-8352-9789d7e3cd89",
      ],
    },
  },
  {
    id: "9c5132c4-3821-4930-aeac-8fd51efd7754",
    data: {
      gender: "F",
      first_name: "Aswani",
      last_name: "Wagle",
      birthday: "",
      avatar: "images/9c5132c4-3821-4930-aeac-8fd51efd7754.png",
    },
    rels: {
      father: "031c799e-c89b-49a4-b75d-38248c8c435b",
      mother: "bd431bca-c92d-4f47-b979-d259dbdfd4d2",
    },
  },
  {
    id: "94cbc839-f8ef-41c0-8352-9789d7e3cd89",
    data: {
      gender: "F",
      first_name: "Anisuya",
      last_name: "Wagle",
      birthday: "",
      avatar: "images/94cbc839-f8ef-41c0-8352-9789d7e3cd89.png",
    },
    rels: {
      father: "031c799e-c89b-49a4-b75d-38248c8c435b",
      mother: "bd431bca-c92d-4f47-b979-d259dbdfd4d2",
    },
  },
  {
    id: "164e533e-54d6-4ca6-959a-502de785e8e0",
    data: {
      gender: "F",
      first_name: "Kopila",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/164e533e-54d6-4ca6-959a-502de785e8e0.png",
    },
    rels: {
      father: "1ee7581f-7c4d-449a-821a-03903a1ec79e",
      mother: "c10892ba-1a5d-43e8-8a47-335ebd2e24d4",
      spouses: ["b97df8e2-75af-49f2-ac58-53ef0499fb34"],
    },
  },
  {
    id: "b97df8e2-75af-49f2-ac58-53ef0499fb34",
    data: {
      gender: "M",
      first_name: "Suman",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/b97df8e2-75af-49f2-ac58-53ef0499fb34.png",
    },
    rels: {
      spouses: ["164e533e-54d6-4ca6-959a-502de785e8e0"],
    },
  },
  {
    id: "2919bd1d-0b87-4caf-b8e7-45465c96241f",
    data: {
      gender: "M",
      first_name: "Bikash",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/2919bd1d-0b87-4caf-b8e7-45465c96241f.png",
    },
    rels: {
      father: "1ee7581f-7c4d-449a-821a-03903a1ec79e",
      mother: "c10892ba-1a5d-43e8-8a47-335ebd2e24d4",
      spouses: ["312be336-8ddd-4d0b-8b3f-f45a97675bd5"],
    },
  },
  {
    id: "312be336-8ddd-4d0b-8b3f-f45a97675bd5",
    data: {
      gender: "F",
      first_name: "Prasanna",
      last_name: "Dahal",
      birthday: "",
      avatar: "images/312be336-8ddd-4d0b-8b3f-f45a97675bd5.png",
    },
    rels: {
      spouses: ["2919bd1d-0b87-4caf-b8e7-45465c96241f"],
    },
  },
  {
    id: "e7c97925-25ff-49ca-974d-3a7500222a27",
    data: {
      gender: "M",
      first_name: "Govind",
      last_name: "Sharma",
      birthday: "",
      avatar: "images/e7c97925-25ff-49ca-974d-3a7500222a27.png",
    },
    rels: {
      father: "7d9cb12a-80d6-4754-9223-5c1241972330",
      mother: "d92fe267-6683-44df-84c1-7b161b102749",
      spouses: ["35e8c72f-ea8e-4f61-be6e-e809c23bb054"],
      children: [
        "d501a15c-92df-4e37-af56-000a4cde58ab",
        "7066c3fc-b007-491e-a2c1-b2097c722bfd",
      ],
    },
  },
  {
    id: "35e8c72f-ea8e-4f61-be6e-e809c23bb054",
    data: {
      gender: "F",
      first_name: "Puja",
      last_name: "Sharma",
      birthday: "",
      avatar: "images/35e8c72f-ea8e-4f61-be6e-e809c23bb054.png",
    },
    rels: {
      spouses: ["e7c97925-25ff-49ca-974d-3a7500222a27"],
      children: [
        "d501a15c-92df-4e37-af56-000a4cde58ab",
        "7066c3fc-b007-491e-a2c1-b2097c722bfd",
      ],
    },
  },
  {
    id: "d501a15c-92df-4e37-af56-000a4cde58ab",
    data: {
      gender: "F",
      first_name: "Sofia",
      last_name: "Sharma",
      birthday: "",
      avatar: "images/d501a15c-92df-4e37-af56-000a4cde58ab.png",
    },
    rels: {
      mother: "35e8c72f-ea8e-4f61-be6e-e809c23bb054",
      father: "e7c97925-25ff-49ca-974d-3a7500222a27",
    },
  },
  {
    id: "7066c3fc-b007-491e-a2c1-b2097c722bfd",
    data: {
      gender: "F",
      first_name: "Shreenidhi",
      last_name: "Sharm",
      birthday: "",
      avatar: "images/7066c3fc-b007-491e-a2c1-b2097c722bfd.png",
    },
    rels: {
      mother: "35e8c72f-ea8e-4f61-be6e-e809c23bb054",
      father: "e7c97925-25ff-49ca-974d-3a7500222a27",
    },
  },
  {
    id: "6cfc499d-0518-43dc-b1c7-d9d01c3c654f",
    data: {
      gender: "M",
      first_name: "Bishnu",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/6cfc499d-0518-43dc-b1c7-d9d01c3c654f.png",
    },
    rels: {
      father: "7d9cb12a-80d6-4754-9223-5c1241972330",
      mother: "d92fe267-6683-44df-84c1-7b161b102749",
      spouses: ["2bec1b49-db0e-4fab-b824-eb4e6e94a3e8"],
      children: [
        "e6e2de3f-a2e1-49d9-8d5a-959257026fc8",
        "f383cd10-135e-4f8f-a60d-e323e6516ae1",
        "a68ac603-c692-4604-ae42-0a455d1d72ce",
      ],
    },
  },
  {
    id: "2bec1b49-db0e-4fab-b824-eb4e6e94a3e8",
    data: {
      gender: "F",
      first_name: "Devi",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/2bec1b49-db0e-4fab-b824-eb4e6e94a3e8.png",
    },
    rels: {
      spouses: ["6cfc499d-0518-43dc-b1c7-d9d01c3c654f"],
      children: [
        "e6e2de3f-a2e1-49d9-8d5a-959257026fc8",
        "f383cd10-135e-4f8f-a60d-e323e6516ae1",
        "a68ac603-c692-4604-ae42-0a455d1d72ce",
      ],
    },
  },
  {
    id: "e6e2de3f-a2e1-49d9-8d5a-959257026fc8",
    data: {
      gender: "M",
      first_name: "Sagun",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/e6e2de3f-a2e1-49d9-8d5a-959257026fc8.png",
    },
    rels: {
      mother: "2bec1b49-db0e-4fab-b824-eb4e6e94a3e8",
      father: "6cfc499d-0518-43dc-b1c7-d9d01c3c654f",
    },
  },
  {
    id: "f383cd10-135e-4f8f-a60d-e323e6516ae1",
    data: {
      gender: "F",
      first_name: "Sadiksha",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/f383cd10-135e-4f8f-a60d-e323e6516ae1.png",
    },
    rels: {
      mother: "2bec1b49-db0e-4fab-b824-eb4e6e94a3e8",
      father: "6cfc499d-0518-43dc-b1c7-d9d01c3c654f",
    },
  },
  {
    id: "a68ac603-c692-4604-ae42-0a455d1d72ce",
    data: {
      gender: "M",
      first_name: "Dibesh",
      "last name": "Khanal",
      birthday: "",
      avatar: "images/a68ac603-c692-4604-ae42-0a455d1d72ce.png",
    },
    rels: {
      mother: "2bec1b49-db0e-4fab-b824-eb4e6e94a3e8",
      father: "6cfc499d-0518-43dc-b1c7-d9d01c3c654f",
    },
  },
  {
    id: "94054f6f-2743-42da-8da7-3eb6a23dd2ef",
    data: {
      gender: "M",
      first_name: "Damodar",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/94054f6f-2743-42da-8da7-3eb6a23dd2ef.png",
    },
    rels: {
      father: "7d9cb12a-80d6-4754-9223-5c1241972330",
      mother: "d92fe267-6683-44df-84c1-7b161b102749",
      spouses: ["79b1c6dd-26a4-4460-9961-bd8c93bde940"],
      children: [
        "8cdda331-d611-43bf-9023-fe7fbd080227",
        "09b07c3f-2f3e-43b2-8b01-5c490ef86cc7",
      ],
    },
  },
  {
    id: "79b1c6dd-26a4-4460-9961-bd8c93bde940",
    data: {
      gender: "F",
      first_name: "Laxmi Dawadi",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/79b1c6dd-26a4-4460-9961-bd8c93bde940.png",
    },
    rels: {
      spouses: ["94054f6f-2743-42da-8da7-3eb6a23dd2ef"],
      children: [
        "8cdda331-d611-43bf-9023-fe7fbd080227",
        "09b07c3f-2f3e-43b2-8b01-5c490ef86cc7",
      ],
    },
  },
  {
    id: "8cdda331-d611-43bf-9023-fe7fbd080227",
    data: {
      gender: "M",
      first_name: "Sushant",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/8cdda331-d611-43bf-9023-fe7fbd080227.png",
    },
    rels: {
      mother: "79b1c6dd-26a4-4460-9961-bd8c93bde940",
      father: "94054f6f-2743-42da-8da7-3eb6a23dd2ef",
    },
  },
  {
    id: "09b07c3f-2f3e-43b2-8b01-5c490ef86cc7",
    data: {
      gender: "F",
      first_name: "Dilasha",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/09b07c3f-2f3e-43b2-8b01-5c490ef86cc7.png",
    },
    rels: {
      mother: "79b1c6dd-26a4-4460-9961-bd8c93bde940",
      father: "94054f6f-2743-42da-8da7-3eb6a23dd2ef",
    },
  },
  {
    id: "b2406e44-76a3-44cc-a635-1056c1723c37",
    data: {
      gender: "M",
      first_name: "Lilawati",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/b2406e44-76a3-44cc-a635-1056c1723c37.png",
    },
    rels: {
      mother: "47af5616-7b87-44f7-abae-dcf0301fc8c4",
      father: "bbd2ba11-91d8-49c7-a859-aa768bf15fe8",
      spouses: ["5b50da12-1fd6-4fe0-866b-35f6794e1528"],
      children: [
        "92b57b46-176e-441b-82c7-dad0a7f592a5",
        "973a245b-1497-4ad7-9f23-768d6cbead8a",
        "295fb980-5e9b-48cd-bbf7-b1af68f066ed",
      ],
    },
  },
  {
    id: "5b50da12-1fd6-4fe0-866b-35f6794e1528",
    data: {
      gender: "F",
      first_name: "Ganga Prashad",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/5b50da12-1fd6-4fe0-866b-35f6794e1528.png",
    },
    rels: {
      spouses: ["b2406e44-76a3-44cc-a635-1056c1723c37"],
      children: [
        "92b57b46-176e-441b-82c7-dad0a7f592a5",
        "973a245b-1497-4ad7-9f23-768d6cbead8a",
        "295fb980-5e9b-48cd-bbf7-b1af68f066ed",
      ],
    },
  },
  {
    id: "92b57b46-176e-441b-82c7-dad0a7f592a5",
    data: {
      gender: "M",
      first_name: "Aatmaram",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/92b57b46-176e-441b-82c7-dad0a7f592a5.png",
    },
    rels: {
      mother: "5b50da12-1fd6-4fe0-866b-35f6794e1528",
      father: "b2406e44-76a3-44cc-a635-1056c1723c37",
      spouses: ["7f2553b6-c298-446a-aee6-671fa9c54805"],
      children: [
        "10f3249f-d89c-4bbe-a79f-d42c15d10752",
        "6afabae5-7314-4cd6-8511-d3a0a065b5a4",
      ],
    },
  },
  {
    id: "7f2553b6-c298-446a-aee6-671fa9c54805",
    data: {
      gender: "F",
      first_name: "Indira",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/7f2553b6-c298-446a-aee6-671fa9c54805.png",
    },
    rels: {
      spouses: ["92b57b46-176e-441b-82c7-dad0a7f592a5"],
      children: [
        "10f3249f-d89c-4bbe-a79f-d42c15d10752",
        "6afabae5-7314-4cd6-8511-d3a0a065b5a4",
      ],
    },
  },
  {
    id: "10f3249f-d89c-4bbe-a79f-d42c15d10752",
    data: {
      gender: "M",
      first_name: "Shiva",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/10f3249f-d89c-4bbe-a79f-d42c15d10752.png",
    },
    rels: {
      mother: "7f2553b6-c298-446a-aee6-671fa9c54805",
      father: "92b57b46-176e-441b-82c7-dad0a7f592a5",
    },
  },
  {
    id: "6afabae5-7314-4cd6-8511-d3a0a065b5a4",
    data: {
      gender: "F",
      first_name: "Sunita",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/6afabae5-7314-4cd6-8511-d3a0a065b5a4.png",
    },
    rels: {
      mother: "7f2553b6-c298-446a-aee6-671fa9c54805",
      father: "92b57b46-176e-441b-82c7-dad0a7f592a5",
    },
  },
  {
    id: "973a245b-1497-4ad7-9f23-768d6cbead8a",
    data: {
      gender: "F",
      first_name: "Pavitra Kumari",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/973a245b-1497-4ad7-9f23-768d6cbead8a.png",
    },
    rels: {
      mother: "5b50da12-1fd6-4fe0-866b-35f6794e1528",
      father: "b2406e44-76a3-44cc-a635-1056c1723c37",
      spouses: ["143e797c-aa57-47df-8344-2a0635f05401"],
      children: [
        "a0b0e899-1081-4229-b80d-e07b62dccde1",
        "a923ce47-93b0-420e-b2c0-a348f08b32ae",
      ],
    },
  },
  {
    id: "143e797c-aa57-47df-8344-2a0635f05401",
    data: {
      gender: "M",
      first_name: "Ghanashyam",
      last_name: "Acharya",
      birthday: "",
      avatar: "images/143e797c-aa57-47df-8344-2a0635f05401.png",
    },
    rels: {
      spouses: ["973a245b-1497-4ad7-9f23-768d6cbead8a"],
      children: [
        "a0b0e899-1081-4229-b80d-e07b62dccde1",
        "a923ce47-93b0-420e-b2c0-a348f08b32ae",
      ],
    },
  },
  {
    id: "a0b0e899-1081-4229-b80d-e07b62dccde1",
    data: {
      gender: "M",
      first_name: "Santosh",
      last_name: "Acharya",
      birthday: "",
      avatar: "images/a0b0e899-1081-4229-b80d-e07b62dccde1.png",
    },
    rels: {
      mother: "973a245b-1497-4ad7-9f23-768d6cbead8a",
      father: "143e797c-aa57-47df-8344-2a0635f05401",
    },
  },
  {
    id: "a923ce47-93b0-420e-b2c0-a348f08b32ae",
    data: {
      gender: "F",
      first_name: "Shanti",
      last_name: "Acharya",
      birthday: "",
      avatar: "images/a923ce47-93b0-420e-b2c0-a348f08b32ae.png",
    },
    rels: {
      mother: "973a245b-1497-4ad7-9f23-768d6cbead8a",
      father: "143e797c-aa57-47df-8344-2a0635f05401",
    },
  },
  {
    id: "295fb980-5e9b-48cd-bbf7-b1af68f066ed",
    data: {
      gender: "M",
      first_name: "Devsharma",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/295fb980-5e9b-48cd-bbf7-b1af68f066ed.png",
    },
    rels: {
      mother: "5b50da12-1fd6-4fe0-866b-35f6794e1528",
      father: "b2406e44-76a3-44cc-a635-1056c1723c37",
      spouses: ["a5f6e311-eecd-4b68-8710-d2640fd9db4d"],
      children: ["668b67d8-d7af-48ad-8c1a-1f9c61c51f45"],
    },
  },
  {
    id: "a5f6e311-eecd-4b68-8710-d2640fd9db4d",
    data: {
      gender: "F",
      first_name: "Laxmi",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/a5f6e311-eecd-4b68-8710-d2640fd9db4d.png",
    },
    rels: {
      spouses: ["295fb980-5e9b-48cd-bbf7-b1af68f066ed"],
      children: ["668b67d8-d7af-48ad-8c1a-1f9c61c51f45"],
    },
  },
  {
    id: "668b67d8-d7af-48ad-8c1a-1f9c61c51f45",
    data: {
      gender: "F",
      first_name: "Sangita",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/668b67d8-d7af-48ad-8c1a-1f9c61c51f45.png",
    },
    rels: {
      mother: "a5f6e311-eecd-4b68-8710-d2640fd9db4d",
      father: "295fb980-5e9b-48cd-bbf7-b1af68f066ed",
    },
  },
  {
    id: "9ccd4ba0-fbde-44bf-b3a2-e1478df08624",
    data: {
      gender: "M",
      first_name: "Ananta Prashad",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/9ccd4ba0-fbde-44bf-b3a2-e1478df08624.png",
    },
    rels: {
      mother: "c8f59027-a3ee-4b1b-83f8-1c0bc5970e32",
      father: "86e88760-bbab-4b6c-abc1-b1cb5331dcc3",
      spouses: ["055c3143-0fcb-47a9-894a-e510c7cfc27e"],
      children: [
        "943d2ed6-51d0-47c0-aef8-7859c06b88ce",
        "9d9bbaa0-e16c-4661-872e-614894c3f061",
        "dcb23aa3-25d5-41f5-b004-19dc4d553af0",
        "723e75c0-c616-48be-9a0d-2d301ca792ae",
        "f4352866-41a2-4e66-94e8-c1a0a4cf64ac",
      ],
    },
  },
  {
    id: "055c3143-0fcb-47a9-894a-e510c7cfc27e",
    data: {
      gender: "F",
      first_name: "Janaki",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/055c3143-0fcb-47a9-894a-e510c7cfc27e.png",
    },
    rels: {
      spouses: ["9ccd4ba0-fbde-44bf-b3a2-e1478df08624"],
      children: [
        "943d2ed6-51d0-47c0-aef8-7859c06b88ce",
        "9d9bbaa0-e16c-4661-872e-614894c3f061",
        "dcb23aa3-25d5-41f5-b004-19dc4d553af0",
        "723e75c0-c616-48be-9a0d-2d301ca792ae",
        "f4352866-41a2-4e66-94e8-c1a0a4cf64ac",
      ],
    },
  },
  {
    id: "943d2ed6-51d0-47c0-aef8-7859c06b88ce",
    data: {
      gender: "M",
      first_name: "Krishna Prashad",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/943d2ed6-51d0-47c0-aef8-7859c06b88ce.png",
    },
    rels: {
      mother: "055c3143-0fcb-47a9-894a-e510c7cfc27e",
      father: "9ccd4ba0-fbde-44bf-b3a2-e1478df08624",
      spouses: ["ed8317ae-cf1f-48a2-9ef3-ca17b15201c3"],
      children: [
        "aac0bda8-d3dd-46c5-b443-2d03c065988c",
        "459e8ac1-7659-41a4-bf71-f06ba6244f86",
        "05ce5fec-a736-408d-aa5f-e23b46782192",
        "c860c11b-d533-4b2a-9d84-1e4b43ee689a",
      ],
    },
  },
  {
    id: "ed8317ae-cf1f-48a2-9ef3-ca17b15201c3",
    data: {
      gender: "F",
      first_name: "Sita",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/ed8317ae-cf1f-48a2-9ef3-ca17b15201c3.png",
    },
    rels: {
      spouses: ["943d2ed6-51d0-47c0-aef8-7859c06b88ce"],
      children: [
        "aac0bda8-d3dd-46c5-b443-2d03c065988c",
        "459e8ac1-7659-41a4-bf71-f06ba6244f86",
        "05ce5fec-a736-408d-aa5f-e23b46782192",
        "c860c11b-d533-4b2a-9d84-1e4b43ee689a",
      ],
    },
  },
  {
    id: "aac0bda8-d3dd-46c5-b443-2d03c065988c",
    data: {
      gender: "F",
      first_name: "Ganga",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/aac0bda8-d3dd-46c5-b443-2d03c065988c.png",
    },
    rels: {
      mother: "ed8317ae-cf1f-48a2-9ef3-ca17b15201c3",
      father: "943d2ed6-51d0-47c0-aef8-7859c06b88ce",
    },
  },
  {
    id: "459e8ac1-7659-41a4-bf71-f06ba6244f86",
    data: {
      gender: "F",
      first_name: "Jamuna",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/459e8ac1-7659-41a4-bf71-f06ba6244f86.png",
    },
    rels: {
      mother: "ed8317ae-cf1f-48a2-9ef3-ca17b15201c3",
      father: "943d2ed6-51d0-47c0-aef8-7859c06b88ce",
    },
  },
  {
    id: "05ce5fec-a736-408d-aa5f-e23b46782192",
    data: {
      gender: "M",
      first_name: "Suraj",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/05ce5fec-a736-408d-aa5f-e23b46782192.png",
    },
    rels: {
      mother: "ed8317ae-cf1f-48a2-9ef3-ca17b15201c3",
      father: "943d2ed6-51d0-47c0-aef8-7859c06b88ce",
    },
  },
  {
    id: "c860c11b-d533-4b2a-9d84-1e4b43ee689a",
    data: {
      gender: "M",
      first_name: "Santosh",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/c860c11b-d533-4b2a-9d84-1e4b43ee689a.png",
    },
    rels: {
      mother: "ed8317ae-cf1f-48a2-9ef3-ca17b15201c3",
      father: "943d2ed6-51d0-47c0-aef8-7859c06b88ce",
    },
  },
  {
    id: "9d9bbaa0-e16c-4661-872e-614894c3f061",
    data: {
      gender: "M",
      first_name: "Bharat",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/9d9bbaa0-e16c-4661-872e-614894c3f061.png",
    },
    rels: {
      mother: "055c3143-0fcb-47a9-894a-e510c7cfc27e",
      father: "9ccd4ba0-fbde-44bf-b3a2-e1478df08624",
      spouses: ["e51701b8-0bb6-408b-99bb-4bc2635f8031"],
      children: [
        "8e81f00d-3e35-4028-b673-8c75b50b4bdb",
        "50536c3b-ef68-4f5c-8d35-2b22c0aef44e",
      ],
    },
  },
  {
    id: "e51701b8-0bb6-408b-99bb-4bc2635f8031",
    data: {
      gender: "F",
      first_name: "Gita",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/e51701b8-0bb6-408b-99bb-4bc2635f8031.png",
    },
    rels: {
      spouses: ["9d9bbaa0-e16c-4661-872e-614894c3f061"],
      children: [
        "8e81f00d-3e35-4028-b673-8c75b50b4bdb",
        "50536c3b-ef68-4f5c-8d35-2b22c0aef44e",
      ],
    },
  },
  {
    id: "8e81f00d-3e35-4028-b673-8c75b50b4bdb",
    data: {
      gender: "M",
      first_name: "Bikash",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/8e81f00d-3e35-4028-b673-8c75b50b4bdb.png",
    },
    rels: {
      mother: "e51701b8-0bb6-408b-99bb-4bc2635f8031",
      father: "9d9bbaa0-e16c-4661-872e-614894c3f061",
    },
  },
  {
    id: "50536c3b-ef68-4f5c-8d35-2b22c0aef44e",
    data: {
      gender: "F",
      first_name: "Sarita",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/50536c3b-ef68-4f5c-8d35-2b22c0aef44e.png",
    },
    rels: {
      mother: "e51701b8-0bb6-408b-99bb-4bc2635f8031",
      father: "9d9bbaa0-e16c-4661-872e-614894c3f061",
    },
  },
  {
    id: "dcb23aa3-25d5-41f5-b004-19dc4d553af0",
    data: {
      gender: "F",
      first_name: "Sita",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/dcb23aa3-25d5-41f5-b004-19dc4d553af0.png",
    },
    rels: {
      mother: "055c3143-0fcb-47a9-894a-e510c7cfc27e",
      father: "9ccd4ba0-fbde-44bf-b3a2-e1478df08624",
      spouses: ["2ce697d6-d7f7-4033-a7dc-d535f1b501e9"],
      children: [
        "0487cfb9-76d2-44c9-af4b-dc1525587672",
        "cd416cf8-7448-4018-8b7b-14d80f2ee8b4",
        "a55f8028-85c1-4af0-bd50-297a5c172c10",
      ],
    },
  },
  {
    id: "2ce697d6-d7f7-4033-a7dc-d535f1b501e9",
    data: {
      gender: "M",
      first_name: "Hari Prashad",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/2ce697d6-d7f7-4033-a7dc-d535f1b501e9.png",
    },
    rels: {
      spouses: ["dcb23aa3-25d5-41f5-b004-19dc4d553af0"],
      children: [
        "0487cfb9-76d2-44c9-af4b-dc1525587672",
        "cd416cf8-7448-4018-8b7b-14d80f2ee8b4",
        "a55f8028-85c1-4af0-bd50-297a5c172c10",
      ],
    },
  },
  {
    id: "0487cfb9-76d2-44c9-af4b-dc1525587672",
    data: {
      gender: "M",
      first_name: "Gokul",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/0487cfb9-76d2-44c9-af4b-dc1525587672.png",
    },
    rels: {
      mother: "dcb23aa3-25d5-41f5-b004-19dc4d553af0",
      father: "2ce697d6-d7f7-4033-a7dc-d535f1b501e9",
      spouses: ["35a5f621-d265-42a3-b880-9a1043bcf15d"],
    },
  },
  {
    id: "35a5f621-d265-42a3-b880-9a1043bcf15d",
    data: {
      gender: "F",
      first_name: "Srijana",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/35a5f621-d265-42a3-b880-9a1043bcf15d.png",
    },
    rels: {
      spouses: ["0487cfb9-76d2-44c9-af4b-dc1525587672"],
    },
  },
  {
    id: "cd416cf8-7448-4018-8b7b-14d80f2ee8b4",
    data: {
      gender: "F",
      first_name: "Susila",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/cd416cf8-7448-4018-8b7b-14d80f2ee8b4.png",
    },
    rels: {
      mother: "dcb23aa3-25d5-41f5-b004-19dc4d553af0",
      father: "2ce697d6-d7f7-4033-a7dc-d535f1b501e9",
    },
  },
  {
    id: "a55f8028-85c1-4af0-bd50-297a5c172c10",
    data: {
      gender: "F",
      first_name: "Bishnu",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/a55f8028-85c1-4af0-bd50-297a5c172c10.png",
    },
    rels: {
      mother: "dcb23aa3-25d5-41f5-b004-19dc4d553af0",
      father: "2ce697d6-d7f7-4033-a7dc-d535f1b501e9",
    },
  },
  {
    id: "723e75c0-c616-48be-9a0d-2d301ca792ae",
    data: {
      gender: "F",
      first_name: "Sakuntala",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/723e75c0-c616-48be-9a0d-2d301ca792ae.png",
    },
    rels: {
      mother: "055c3143-0fcb-47a9-894a-e510c7cfc27e",
      father: "9ccd4ba0-fbde-44bf-b3a2-e1478df08624",
      spouses: ["3a4705a1-0fb9-4640-85bd-f2d6a1f0ed84"],
      children: [
        "ef7a301c-d30b-487f-9b63-69ca3760a272",
        "e412bfae-a2ff-4740-bbe5-020d79fa9a86",
      ],
    },
  },
  {
    id: "3a4705a1-0fb9-4640-85bd-f2d6a1f0ed84",
    data: {
      gender: "M",
      first_name: "Kul Prashad",
      last_name: "Parajuli",
      birthday: "",
      avatar: "images/3a4705a1-0fb9-4640-85bd-f2d6a1f0ed84.png",
    },
    rels: {
      spouses: ["723e75c0-c616-48be-9a0d-2d301ca792ae"],
      children: [
        "ef7a301c-d30b-487f-9b63-69ca3760a272",
        "e412bfae-a2ff-4740-bbe5-020d79fa9a86",
      ],
    },
  },
  {
    id: "ef7a301c-d30b-487f-9b63-69ca3760a272",
    data: {
      gender: "F",
      first_name: "Sunita",
      last_name: "Parajuli",
      birthday: "",
      avatar: "images/ef7a301c-d30b-487f-9b63-69ca3760a272.png",
    },
    rels: {
      father: "3a4705a1-0fb9-4640-85bd-f2d6a1f0ed84",
      mother: "723e75c0-c616-48be-9a0d-2d301ca792ae",
    },
  },
  {
    id: "e412bfae-a2ff-4740-bbe5-020d79fa9a86",
    data: {
      gender: "F",
      first_name: "Susmita",
      last_name: "Parajuli",
      birthday: "",
      avatar: "images/e412bfae-a2ff-4740-bbe5-020d79fa9a86.png",
    },
    rels: {
      father: "3a4705a1-0fb9-4640-85bd-f2d6a1f0ed84",
      mother: "723e75c0-c616-48be-9a0d-2d301ca792ae",
    },
  },
  {
    id: "f4352866-41a2-4e66-94e8-c1a0a4cf64ac",
    data: {
      gender: "M",
      first_name: "Mukunda",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/f4352866-41a2-4e66-94e8-c1a0a4cf64ac.png",
    },
    rels: {
      mother: "055c3143-0fcb-47a9-894a-e510c7cfc27e",
      father: "9ccd4ba0-fbde-44bf-b3a2-e1478df08624",
      spouses: ["4b012a8d-a0a4-4f8f-b4dd-60aa0091d048"],
      children: ["513783ec-a4f9-4927-a0d4-2cc3d8c01c9f"],
    },
  },
  {
    id: "4b012a8d-a0a4-4f8f-b4dd-60aa0091d048",
    data: {
      gender: "F",
      first_name: "Laxmi",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/4b012a8d-a0a4-4f8f-b4dd-60aa0091d048.png",
    },
    rels: {
      spouses: ["f4352866-41a2-4e66-94e8-c1a0a4cf64ac"],
      children: ["513783ec-a4f9-4927-a0d4-2cc3d8c01c9f"],
    },
  },
  {
    id: "513783ec-a4f9-4927-a0d4-2cc3d8c01c9f",
    data: {
      gender: "M",
      first_name: "Gaurav",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/513783ec-a4f9-4927-a0d4-2cc3d8c01c9f.png",
    },
    rels: {
      mother: "4b012a8d-a0a4-4f8f-b4dd-60aa0091d048",
      father: "f4352866-41a2-4e66-94e8-c1a0a4cf64ac",
    },
  },
  {
    id: "b432fc62-ab64-4908-9f02-3233f5e49c35",
    data: {
      gender: "M",
      first_name: "Indra Prashad",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/b432fc62-ab64-4908-9f02-3233f5e49c35.png",
    },
    rels: {
      mother: "c8f59027-a3ee-4b1b-83f8-1c0bc5970e32",
      father: "86e88760-bbab-4b6c-abc1-b1cb5331dcc3",
      spouses: ["24765561-0dc1-4817-823b-9f662ac29fd6"],
      children: [
        "b7c1a6d6-f7b2-496d-87b0-328d96a96db2",
        "052f233d-d5f7-4085-a94d-3d0f2048779a",
        "ce7e98cf-a652-4d6b-b870-4bc5b8c49390",
        "ecb8956c-e9d8-429f-80a8-4e8d7ca8cdb9",
        "040696ff-0b70-4201-b9a7-31fd492c976f",
        "9028b42d-3505-440e-8e69-24c7dfb292c6",
        "cf62ee15-0f0f-454c-b6c9-1b5cef2d0afd",
        "c9c62892-2f39-40e3-a8bb-812a2e0c8046",
        "69b6d663-d79b-407b-8d8e-487762428ee8",
      ],
    },
  },
  {
    id: "24765561-0dc1-4817-823b-9f662ac29fd6",
    data: {
      gender: "F",
      first_name: "Uma",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/24765561-0dc1-4817-823b-9f662ac29fd6.png",
    },
    rels: {
      spouses: ["b432fc62-ab64-4908-9f02-3233f5e49c35"],
      children: [
        "b7c1a6d6-f7b2-496d-87b0-328d96a96db2",
        "ce7e98cf-a652-4d6b-b870-4bc5b8c49390",
        "052f233d-d5f7-4085-a94d-3d0f2048779a",
        "ecb8956c-e9d8-429f-80a8-4e8d7ca8cdb9",
        "9028b42d-3505-440e-8e69-24c7dfb292c6",
        "cf62ee15-0f0f-454c-b6c9-1b5cef2d0afd",
        "c9c62892-2f39-40e3-a8bb-812a2e0c8046",
        "69b6d663-d79b-407b-8d8e-487762428ee8",
        "040696ff-0b70-4201-b9a7-31fd492c976f",
      ],
    },
  },
  {
    id: "b7c1a6d6-f7b2-496d-87b0-328d96a96db2",
    data: {
      gender: "M",
      first_name: "Basudev",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/b7c1a6d6-f7b2-496d-87b0-328d96a96db2.png",
    },
    rels: {
      mother: "24765561-0dc1-4817-823b-9f662ac29fd6",
      father: "b432fc62-ab64-4908-9f02-3233f5e49c35",
      spouses: ["cec0727a-4f9d-46aa-b76b-7492acb1fcad"],
      children: [
        "9924cdca-94b9-4fcd-b5cc-2c32b9be6691",
        "299fb45a-3f01-415a-85ec-eddf49360847",
      ],
    },
  },
  {
    id: "cec0727a-4f9d-46aa-b76b-7492acb1fcad",
    data: {
      gender: "F",
      first_name: "Gita",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/cec0727a-4f9d-46aa-b76b-7492acb1fcad.png",
    },
    rels: {
      spouses: ["b7c1a6d6-f7b2-496d-87b0-328d96a96db2"],
      children: [
        "9924cdca-94b9-4fcd-b5cc-2c32b9be6691",
        "299fb45a-3f01-415a-85ec-eddf49360847",
      ],
    },
  },
  {
    id: "9924cdca-94b9-4fcd-b5cc-2c32b9be6691",
    data: {
      gender: "M",
      first_name: "Anil",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/9924cdca-94b9-4fcd-b5cc-2c32b9be6691.png",
    },
    rels: {
      mother: "cec0727a-4f9d-46aa-b76b-7492acb1fcad",
      father: "b7c1a6d6-f7b2-496d-87b0-328d96a96db2",
      spouses: ["1b0cb5ba-b77f-4c9e-84c8-adb9ceeae51b"],
    },
  },
  {
    id: "299fb45a-3f01-415a-85ec-eddf49360847",
    data: {
      gender: "M",
      first_name: "Sunil",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/299fb45a-3f01-415a-85ec-eddf49360847.png",
    },
    rels: {
      mother: "cec0727a-4f9d-46aa-b76b-7492acb1fcad",
      father: "b7c1a6d6-f7b2-496d-87b0-328d96a96db2",
    },
  },
  {
    id: "1b0cb5ba-b77f-4c9e-84c8-adb9ceeae51b",
    data: {
      gender: "F",
      first_name: "Saru",
      last_name: "Acharya",
      birthday: "",
      avatar: "images/1b0cb5ba-b77f-4c9e-84c8-adb9ceeae51b.png",
    },
    rels: {
      spouses: ["9924cdca-94b9-4fcd-b5cc-2c32b9be6691"],
    },
  },
  {
    id: "ce7e98cf-a652-4d6b-b870-4bc5b8c49390",
    data: {
      gender: "M",
      first_name: "Seskanta",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/ce7e98cf-a652-4d6b-b870-4bc5b8c49390.png",
    },
    rels: {
      mother: "24765561-0dc1-4817-823b-9f662ac29fd6",
      father: "b432fc62-ab64-4908-9f02-3233f5e49c35",
      spouses: ["9efe8450-6443-41be-b647-ce219a9ef791"],
      children: ["c2b77b95-be85-48bf-9408-6d2222e0a9e0"],
    },
  },
  {
    id: "9efe8450-6443-41be-b647-ce219a9ef791",
    data: {
      gender: "F",
      first_name: "Jamuna",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/9efe8450-6443-41be-b647-ce219a9ef791.png",
    },
    rels: {
      spouses: ["ce7e98cf-a652-4d6b-b870-4bc5b8c49390"],
      children: ["c2b77b95-be85-48bf-9408-6d2222e0a9e0"],
    },
  },
  {
    id: "052f233d-d5f7-4085-a94d-3d0f2048779a",
    data: {
      gender: "F",
      first_name: "Gauri",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/052f233d-d5f7-4085-a94d-3d0f2048779a.png",
    },
    rels: {
      mother: "24765561-0dc1-4817-823b-9f662ac29fd6",
      father: "b432fc62-ab64-4908-9f02-3233f5e49c35",
      spouses: ["9ca88140-1ba6-4189-85eb-6f4ab9b51f35"],
      children: [
        "783e3f34-c92a-4009-a2d8-0d67a9b5483c",
        "d59e3091-dae8-4f90-9c4d-aa3a60200b17",
        "42510ea6-c627-4e2a-b102-ebf59ebdef00",
      ],
    },
  },
  {
    id: "9ca88140-1ba6-4189-85eb-6f4ab9b51f35",
    data: {
      gender: "M",
      first_name: "Rishiram",
      last_name: "Gautam",
      birthday: "",
      avatar: "images/9ca88140-1ba6-4189-85eb-6f4ab9b51f35.png",
    },
    rels: {
      spouses: ["052f233d-d5f7-4085-a94d-3d0f2048779a"],
      children: [
        "783e3f34-c92a-4009-a2d8-0d67a9b5483c",
        "d59e3091-dae8-4f90-9c4d-aa3a60200b17",
        "42510ea6-c627-4e2a-b102-ebf59ebdef00",
      ],
    },
  },
  {
    id: "783e3f34-c92a-4009-a2d8-0d67a9b5483c",
    data: {
      gender: "M",
      first_name: "Santosh",
      last_name: "Gautam",
      birthday: "",
      avatar: "images/783e3f34-c92a-4009-a2d8-0d67a9b5483c.png",
    },
    rels: {
      father: "9ca88140-1ba6-4189-85eb-6f4ab9b51f35",
      mother: "052f233d-d5f7-4085-a94d-3d0f2048779a",
      spouses: ["725b35a8-4d5e-4871-98ba-202297a5c282"],
    },
  },
  {
    id: "725b35a8-4d5e-4871-98ba-202297a5c282",
    data: {
      gender: "F",
      first_name: "Karuna",
      last_name: "Gautam",
      birthday: "",
      avatar: "images/725b35a8-4d5e-4871-98ba-202297a5c282.png",
    },
    rels: {
      spouses: ["783e3f34-c92a-4009-a2d8-0d67a9b5483c"],
    },
  },
  {
    id: "d59e3091-dae8-4f90-9c4d-aa3a60200b17",
    data: {
      gender: "F",
      first_name: "Deepa",
      last_name: "Gautal",
      birthday: "",
      avatar: "images/d59e3091-dae8-4f90-9c4d-aa3a60200b17.png",
    },
    rels: {
      mother: "052f233d-d5f7-4085-a94d-3d0f2048779a",
      father: "9ca88140-1ba6-4189-85eb-6f4ab9b51f35",
    },
  },
  {
    id: "42510ea6-c627-4e2a-b102-ebf59ebdef00",
    data: {
      gender: "F",
      first_name: "Kalpana",
      last_name: "Gautam",
      birthday: "",
      avatar: "images/42510ea6-c627-4e2a-b102-ebf59ebdef00.png",
    },
    rels: {
      mother: "052f233d-d5f7-4085-a94d-3d0f2048779a",
      father: "9ca88140-1ba6-4189-85eb-6f4ab9b51f35",
    },
  },
  {
    id: "c2b77b95-be85-48bf-9408-6d2222e0a9e0",
    data: {
      gender: "M",
      first_name: "Pratik",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/c2b77b95-be85-48bf-9408-6d2222e0a9e0.png",
    },
    rels: {
      mother: "9efe8450-6443-41be-b647-ce219a9ef791",
      father: "ce7e98cf-a652-4d6b-b870-4bc5b8c49390",
    },
  },
  {
    id: "ecb8956c-e9d8-429f-80a8-4e8d7ca8cdb9",
    data: {
      gender: "F",
      first_name: "Sarada",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/ecb8956c-e9d8-429f-80a8-4e8d7ca8cdb9.png",
    },
    rels: {
      mother: "24765561-0dc1-4817-823b-9f662ac29fd6",
      father: "b432fc62-ab64-4908-9f02-3233f5e49c35",
      spouses: ["490a3966-2146-4964-a588-8447e92d82b0"],
    },
  },
  {
    id: "490a3966-2146-4964-a588-8447e92d82b0",
    data: {
      gender: "M",
      first_name: "Krishna",
      last_name: "Pant",
      birthday: "",
      avatar: "images/490a3966-2146-4964-a588-8447e92d82b0.png",
    },
    rels: {
      spouses: ["ecb8956c-e9d8-429f-80a8-4e8d7ca8cdb9"],
    },
  },
  {
    id: "9028b42d-3505-440e-8e69-24c7dfb292c6",
    data: {
      gender: "F",
      first_name: "Gita",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/9028b42d-3505-440e-8e69-24c7dfb292c6.png",
    },
    rels: {
      mother: "24765561-0dc1-4817-823b-9f662ac29fd6",
      father: "b432fc62-ab64-4908-9f02-3233f5e49c35",
      spouses: ["4d8f433b-ffb0-4b0a-9584-607a9a6d5138"],
      children: [
        "f70d5cc5-01eb-469c-9f65-2cf2da43f619",
        "22a85985-c55d-42d6-a16a-7a96745ea452",
      ],
    },
  },
  {
    id: "4d8f433b-ffb0-4b0a-9584-607a9a6d5138",
    data: {
      gender: "M",
      first_name: "Raju",
      last_name: "Sapkota",
      birthday: "",
      avatar: "images/4d8f433b-ffb0-4b0a-9584-607a9a6d5138.png",
    },
    rels: {
      spouses: ["9028b42d-3505-440e-8e69-24c7dfb292c6"],
      children: [
        "f70d5cc5-01eb-469c-9f65-2cf2da43f619",
        "22a85985-c55d-42d6-a16a-7a96745ea452",
      ],
    },
  },
  {
    id: "f70d5cc5-01eb-469c-9f65-2cf2da43f619",
    data: {
      gender: "M",
      first_name: "Aashiq",
      last_name: "Shapkota",
      birthday: "",
      avatar: "images/f70d5cc5-01eb-469c-9f65-2cf2da43f619.png",
    },
    rels: {
      father: "4d8f433b-ffb0-4b0a-9584-607a9a6d5138",
      mother: "9028b42d-3505-440e-8e69-24c7dfb292c6",
    },
  },
  {
    id: "22a85985-c55d-42d6-a16a-7a96745ea452",
    data: {
      gender: "M",
      first_name: "Sagar",
      last_name: "Sapkota",
      birthday: "",
      avatar: "images/22a85985-c55d-42d6-a16a-7a96745ea452.png",
    },
    rels: {
      mother: "9028b42d-3505-440e-8e69-24c7dfb292c6",
      father: "4d8f433b-ffb0-4b0a-9584-607a9a6d5138",
    },
  },
  {
    id: "cf62ee15-0f0f-454c-b6c9-1b5cef2d0afd",
    data: {
      gender: "M",
      first_name: "Shiva",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/cf62ee15-0f0f-454c-b6c9-1b5cef2d0afd.png",
    },
    rels: {
      mother: "24765561-0dc1-4817-823b-9f662ac29fd6",
      father: "b432fc62-ab64-4908-9f02-3233f5e49c35",
      spouses: ["ecdf48e0-af56-4c45-b828-dc42b6e54f08"],
    },
  },
  {
    id: "ecdf48e0-af56-4c45-b828-dc42b6e54f08",
    data: {
      gender: "F",
      first_name: "Shanti",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/ecdf48e0-af56-4c45-b828-dc42b6e54f08.png",
    },
    rels: {
      spouses: ["cf62ee15-0f0f-454c-b6c9-1b5cef2d0afd"],
    },
  },
  {
    id: "c9c62892-2f39-40e3-a8bb-812a2e0c8046",
    data: {
      gender: "F",
      first_name: "Radha",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/c9c62892-2f39-40e3-a8bb-812a2e0c8046.png",
    },
    rels: {
      mother: "24765561-0dc1-4817-823b-9f662ac29fd6",
      father: "b432fc62-ab64-4908-9f02-3233f5e49c35",
    },
  },
  {
    id: "69b6d663-d79b-407b-8d8e-487762428ee8",
    data: {
      gender: "F",
      first_name: "Sarada",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/69b6d663-d79b-407b-8d8e-487762428ee8.png",
    },
    rels: {
      mother: "24765561-0dc1-4817-823b-9f662ac29fd6",
      father: "b432fc62-ab64-4908-9f02-3233f5e49c35",
    },
  },
  {
    id: "fa0e085f-9f57-42a3-9a22-1564e452a1a9",
    data: {
      gender: "M",
      first_name: "Liladhar Prashad",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/fa0e085f-9f57-42a3-9a22-1564e452a1a9.png",
    },
    rels: {
      mother: "c8f59027-a3ee-4b1b-83f8-1c0bc5970e32",
      father: "86e88760-bbab-4b6c-abc1-b1cb5331dcc3",
      spouses: ["1fc96107-901e-4fcf-98a0-dc5b98c2324d"],
      children: [
        "1fb85d2c-4c5a-48bf-a814-9cd3f4bd386f",
        "2fa8d33d-cedd-4eab-bec2-54e706c8a9c7",
        "7cf10f6a-1575-4fb2-ac06-8e12b49b718e",
        "329503c6-8e6d-4bb3-94e0-d4e4512e0794",
        "f9f2f7b0-f2fc-47b6-b432-363fa96514bc",
      ],
    },
  },
  {
    id: "1fc96107-901e-4fcf-98a0-dc5b98c2324d",
    data: {
      gender: "F",
      first_name: "Gop Kumari",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/1fc96107-901e-4fcf-98a0-dc5b98c2324d.png",
    },
    rels: {
      spouses: ["fa0e085f-9f57-42a3-9a22-1564e452a1a9"],
      children: [
        "1fb85d2c-4c5a-48bf-a814-9cd3f4bd386f",
        "2fa8d33d-cedd-4eab-bec2-54e706c8a9c7",
        "7cf10f6a-1575-4fb2-ac06-8e12b49b718e",
        "329503c6-8e6d-4bb3-94e0-d4e4512e0794",
        "f9f2f7b0-f2fc-47b6-b432-363fa96514bc",
      ],
    },
  },
  {
    id: "1fb85d2c-4c5a-48bf-a814-9cd3f4bd386f",
    data: {
      gender: "M",
      first_name: "Gopal",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/1fb85d2c-4c5a-48bf-a814-9cd3f4bd386f.png",
    },
    rels: {
      mother: "1fc96107-901e-4fcf-98a0-dc5b98c2324d",
      father: "fa0e085f-9f57-42a3-9a22-1564e452a1a9",
      spouses: ["92eb121e-2891-41e9-95e7-2b43660875b5"],
      children: [
        "54da8490-ca94-4319-ac2f-78c01bd03013",
        "a2ffbea4-3588-4606-a0a5-f3957d05a6b6",
        "2b95e1da-aef6-471d-8e35-76d9ddaf6982",
      ],
    },
  },
  {
    id: "92eb121e-2891-41e9-95e7-2b43660875b5",
    data: {
      gender: "F",
      first_name: "Gyanu",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/92eb121e-2891-41e9-95e7-2b43660875b5.png",
    },
    rels: {
      spouses: ["1fb85d2c-4c5a-48bf-a814-9cd3f4bd386f"],
      children: [
        "54da8490-ca94-4319-ac2f-78c01bd03013",
        "a2ffbea4-3588-4606-a0a5-f3957d05a6b6",
        "2b95e1da-aef6-471d-8e35-76d9ddaf6982",
      ],
    },
  },
  {
    id: "54da8490-ca94-4319-ac2f-78c01bd03013",
    data: {
      gender: "F",
      first_name: "Kalpana",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/54da8490-ca94-4319-ac2f-78c01bd03013.png",
    },
    rels: {
      mother: "92eb121e-2891-41e9-95e7-2b43660875b5",
      father: "1fb85d2c-4c5a-48bf-a814-9cd3f4bd386f",
    },
  },
  {
    id: "a2ffbea4-3588-4606-a0a5-f3957d05a6b6",
    data: {
      gender: "F",
      first_name: "Deepika",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/a2ffbea4-3588-4606-a0a5-f3957d05a6b6.png",
    },
    rels: {
      mother: "92eb121e-2891-41e9-95e7-2b43660875b5",
      father: "1fb85d2c-4c5a-48bf-a814-9cd3f4bd386f",
    },
  },
  {
    id: "2b95e1da-aef6-471d-8e35-76d9ddaf6982",
    data: {
      gender: "M",
      first_name: "Jivan",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/2b95e1da-aef6-471d-8e35-76d9ddaf6982.png",
    },
    rels: {
      mother: "92eb121e-2891-41e9-95e7-2b43660875b5",
      father: "1fb85d2c-4c5a-48bf-a814-9cd3f4bd386f",
    },
  },
  {
    id: "2fa8d33d-cedd-4eab-bec2-54e706c8a9c7",
    data: {
      gender: "F",
      first_name: "Maya",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/2fa8d33d-cedd-4eab-bec2-54e706c8a9c7.png",
    },
    rels: {
      mother: "1fc96107-901e-4fcf-98a0-dc5b98c2324d",
      father: "fa0e085f-9f57-42a3-9a22-1564e452a1a9",
      spouses: ["3fe89fb9-f6b1-4e7b-b8ec-468d08181025"],
      children: [
        "e16aac8b-ab94-4bc1-b9e3-f25550f55726",
        "32c85478-3dc6-42c9-80e1-1f6ffecc345e",
        "ca5dd9ff-e1b2-4a1c-9f7b-e0084a14ca35",
      ],
    },
  },
  {
    id: "3fe89fb9-f6b1-4e7b-b8ec-468d08181025",
    data: {
      gender: "M",
      first_name: "<> Prashad",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/3fe89fb9-f6b1-4e7b-b8ec-468d08181025.png",
    },
    rels: {
      spouses: ["2fa8d33d-cedd-4eab-bec2-54e706c8a9c7"],
      children: [
        "e16aac8b-ab94-4bc1-b9e3-f25550f55726",
        "32c85478-3dc6-42c9-80e1-1f6ffecc345e",
        "ca5dd9ff-e1b2-4a1c-9f7b-e0084a14ca35",
      ],
    },
  },
  {
    id: "e16aac8b-ab94-4bc1-b9e3-f25550f55726",
    data: {
      gender: "M",
      first_name: "Surya",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/e16aac8b-ab94-4bc1-b9e3-f25550f55726.png",
    },
    rels: {
      mother: "2fa8d33d-cedd-4eab-bec2-54e706c8a9c7",
      father: "3fe89fb9-f6b1-4e7b-b8ec-468d08181025",
    },
  },
  {
    id: "32c85478-3dc6-42c9-80e1-1f6ffecc345e",
    data: {
      gender: "F",
      first_name: "Sarita",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/32c85478-3dc6-42c9-80e1-1f6ffecc345e.png",
    },
    rels: {
      mother: "2fa8d33d-cedd-4eab-bec2-54e706c8a9c7",
      father: "3fe89fb9-f6b1-4e7b-b8ec-468d08181025",
    },
  },
  {
    id: "ca5dd9ff-e1b2-4a1c-9f7b-e0084a14ca35",
    data: {
      gender: "F",
      first_name: "Sharmila",
      last_name: "Paudel",
      birthday: "",
      avatar: "images/ca5dd9ff-e1b2-4a1c-9f7b-e0084a14ca35.png",
    },
    rels: {
      mother: "2fa8d33d-cedd-4eab-bec2-54e706c8a9c7",
      father: "3fe89fb9-f6b1-4e7b-b8ec-468d08181025",
    },
  },
  {
    id: "7cf10f6a-1575-4fb2-ac06-8e12b49b718e",
    data: {
      gender: "M",
      first_name: "Bhuwani",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/7cf10f6a-1575-4fb2-ac06-8e12b49b718e.png",
    },
    rels: {
      mother: "1fc96107-901e-4fcf-98a0-dc5b98c2324d",
      father: "fa0e085f-9f57-42a3-9a22-1564e452a1a9",
      spouses: ["b9d9ebc3-6ccf-4073-a675-fd48672e5994"],
      children: [
        "93e6f13f-22f1-4031-91ec-4ea07972eb87",
        "10e02619-a16f-4805-a0af-458469a04333",
      ],
    },
  },
  {
    id: "b9d9ebc3-6ccf-4073-a675-fd48672e5994",
    data: {
      gender: "F",
      first_name: "Radha",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/b9d9ebc3-6ccf-4073-a675-fd48672e5994.png",
    },
    rels: {
      spouses: ["7cf10f6a-1575-4fb2-ac06-8e12b49b718e"],
      children: [
        "93e6f13f-22f1-4031-91ec-4ea07972eb87",
        "10e02619-a16f-4805-a0af-458469a04333",
      ],
    },
  },
  {
    id: "93e6f13f-22f1-4031-91ec-4ea07972eb87",
    data: {
      gender: "F",
      first_name: "Manisha",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/93e6f13f-22f1-4031-91ec-4ea07972eb87.png",
    },
    rels: {
      mother: "b9d9ebc3-6ccf-4073-a675-fd48672e5994",
      father: "7cf10f6a-1575-4fb2-ac06-8e12b49b718e",
    },
  },
  {
    id: "10e02619-a16f-4805-a0af-458469a04333",
    data: {
      gender: "M",
      first_name: "Manish",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/10e02619-a16f-4805-a0af-458469a04333.png",
    },
    rels: {
      mother: "b9d9ebc3-6ccf-4073-a675-fd48672e5994",
      father: "7cf10f6a-1575-4fb2-ac06-8e12b49b718e",
    },
  },
  {
    id: "329503c6-8e6d-4bb3-94e0-d4e4512e0794",
    data: {
      gender: "M",
      first_name: "Deepak",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/329503c6-8e6d-4bb3-94e0-d4e4512e0794.png",
    },
    rels: {
      mother: "1fc96107-901e-4fcf-98a0-dc5b98c2324d",
      father: "fa0e085f-9f57-42a3-9a22-1564e452a1a9",
      spouses: ["259a1847-9c62-4f5b-81db-c3f269560316"],
      children: [
        "76ce0262-b69c-417a-9f90-d7103c31cf43",
        "34a1bbd6-7138-4e6d-b19c-2bf41e2047be",
      ],
    },
  },
  {
    id: "259a1847-9c62-4f5b-81db-c3f269560316",
    data: {
      gender: "F",
      first_name: "Bhagawati",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/259a1847-9c62-4f5b-81db-c3f269560316.png",
    },
    rels: {
      spouses: ["329503c6-8e6d-4bb3-94e0-d4e4512e0794"],
      children: [
        "76ce0262-b69c-417a-9f90-d7103c31cf43",
        "34a1bbd6-7138-4e6d-b19c-2bf41e2047be",
      ],
    },
  },
  {
    id: "76ce0262-b69c-417a-9f90-d7103c31cf43",
    data: {
      gender: "M",
      first_name: "Dipesh",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/76ce0262-b69c-417a-9f90-d7103c31cf43.png",
    },
    rels: {
      mother: "259a1847-9c62-4f5b-81db-c3f269560316",
      father: "329503c6-8e6d-4bb3-94e0-d4e4512e0794",
    },
  },
  {
    id: "34a1bbd6-7138-4e6d-b19c-2bf41e2047be",
    data: {
      gender: "M",
      first_name: "Diwas",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/34a1bbd6-7138-4e6d-b19c-2bf41e2047be.png",
    },
    rels: {
      mother: "259a1847-9c62-4f5b-81db-c3f269560316",
      father: "329503c6-8e6d-4bb3-94e0-d4e4512e0794",
    },
  },
  {
    id: "f9f2f7b0-f2fc-47b6-b432-363fa96514bc",
    data: {
      gender: "M",
      first_name: "Yadav",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/f9f2f7b0-f2fc-47b6-b432-363fa96514bc.png",
    },
    rels: {
      mother: "1fc96107-901e-4fcf-98a0-dc5b98c2324d",
      father: "fa0e085f-9f57-42a3-9a22-1564e452a1a9",
      spouses: ["4d1af9ac-d6bd-4f98-b47e-ffdc9dedc0de"],
      children: ["64117c6e-dcf4-4a7d-955e-9c5333bb9fda"],
    },
  },
  {
    id: "4d1af9ac-d6bd-4f98-b47e-ffdc9dedc0de",
    data: {
      gender: "F",
      first_name: "Sarada",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/4d1af9ac-d6bd-4f98-b47e-ffdc9dedc0de.png",
    },
    rels: {
      spouses: ["f9f2f7b0-f2fc-47b6-b432-363fa96514bc"],
      children: ["64117c6e-dcf4-4a7d-955e-9c5333bb9fda"],
    },
  },
  {
    id: "64117c6e-dcf4-4a7d-955e-9c5333bb9fda",
    data: {
      gender: "M",
      first_name: "Sandesh",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/64117c6e-dcf4-4a7d-955e-9c5333bb9fda.png",
    },
    rels: {
      mother: "4d1af9ac-d6bd-4f98-b47e-ffdc9dedc0de",
      father: "f9f2f7b0-f2fc-47b6-b432-363fa96514bc",
    },
  },
  {
    id: "040696ff-0b70-4201-b9a7-31fd492c976f",
    data: {
      gender: "M",
      first_name: "Dev Prashad",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/040696ff-0b70-4201-b9a7-31fd492c976f.png",
    },
    rels: {
      mother: "24765561-0dc1-4817-823b-9f662ac29fd6",
      father: "b432fc62-ab64-4908-9f02-3233f5e49c35",
      spouses: ["57d50cd5-f757-47bb-bad4-a7ea98aef1ec"],
      children: [
        "d804a08b-fe6e-42d7-9e13-d31d59a90e83",
        "9a1a780e-04c3-4c09-b84d-c31fd7ba724d",
      ],
    },
  },
  {
    id: "57d50cd5-f757-47bb-bad4-a7ea98aef1ec",
    data: {
      gender: "F",
      first_name: "Sarada",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/57d50cd5-f757-47bb-bad4-a7ea98aef1ec.png",
    },
    rels: {
      spouses: ["040696ff-0b70-4201-b9a7-31fd492c976f"],
      children: [
        "d804a08b-fe6e-42d7-9e13-d31d59a90e83",
        "9a1a780e-04c3-4c09-b84d-c31fd7ba724d",
      ],
    },
  },
  {
    id: "d804a08b-fe6e-42d7-9e13-d31d59a90e83",
    data: {
      gender: "F",
      first_name: "Prakriti",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/d804a08b-fe6e-42d7-9e13-d31d59a90e83.png",
    },
    rels: {
      mother: "57d50cd5-f757-47bb-bad4-a7ea98aef1ec",
      father: "040696ff-0b70-4201-b9a7-31fd492c976f",
    },
  },
  {
    id: "9a1a780e-04c3-4c09-b84d-c31fd7ba724d",
    data: {
      gender: "M",
      first_name: "Pratik",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/9a1a780e-04c3-4c09-b84d-c31fd7ba724d.png",
    },
    rels: {
      mother: "57d50cd5-f757-47bb-bad4-a7ea98aef1ec",
      father: "040696ff-0b70-4201-b9a7-31fd492c976f",
    },
  },
  {
    id: "135c6423-5095-4b35-9cfd-84dc20ab3f85",
    data: {
      gender: "F",
      first_name: "Kali",
      last_name: "Khanal",
      birthday: "",
      avatar: "images/135c6423-5095-4b35-9cfd-84dc20ab3f85.png",
    },
    rels: {
      spouses: ["13e1003a-224e-43f4-88a0-83319d9da618"],
    },
  },
];
