import React from "react";

const PlusIcon: React.ReactNode = <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    stroke="none"
    fill="currentcolor"
>
    <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
</svg>;

const MinusIcon: React.ReactNode = <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    stroke="none"
    fill="currentcolor"
>
    <path d="M24 10h-20v4h20z" />
</svg>;

export { PlusIcon, MinusIcon }