import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import FamilyTree from './familytree';

const Home: React.FC = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])
    return <div>
        <div id="family-tree">
            <FamilyTree cardDisplayType="square" />
        </div>
    </div>;
};

export default Home;