import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';

import Home from './components/Home';
import MyTree from './components/mytree';
import Navigation from './components/Navigation';

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/new" element={<MyTree />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
