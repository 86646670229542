import React from "react";
import { PlusIcon, MinusIcon } from "./icons";

const RectangleCardView = (props: any) => {
  const nodeDatum = props.nodeDatum,
    toggleNode = props.toggleNode,
    foreignObjectProps = props.foreignObjectProps || {};

  const hasChildren = nodeDatum.children?.length;
  const isCompany = nodeDatum.attributes?.isCompany;
  return (
    <React.Fragment>
      <foreignObject
        {...foreignObjectProps}
        width="200"
        height="100vh"
        x="-100"
        y=""
      >
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{nodeDatum.name}</h5>
            <h6 className="card-subtitle mb-2 text-muted">
              {nodeDatum.attributes?.title}
            </h6>
            <p className="card-text">{nodeDatum.attributes?.description}</p>
            <p className="card-text">{nodeDatum.attributes?.orgNumber}</p>
          </div>
          {hasChildren ? (
            <button className="toggle" type="button" onClick={toggleNode}>
              {nodeDatum.__rd3t.collapsed ? PlusIcon : MinusIcon}
            </button>
          ) : null}
        </div>
      </foreignObject>
    </React.Fragment>
  );
};

export { RectangleCardView };
